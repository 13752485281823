<template>
  <v-runtime-template
    :class="['cms-block-content', { container: sync }]"
    v-if="getCmsData"
    :template="`<div>` + decodedContent + `</div>`"
  ></v-runtime-template>
</template>

<script>
import { isServer } from "@storefront/core/helpers";
import VRuntimeTemplate from "v-runtime-template";

export default {
  name: "CmsBlockSimple",
  components: { VRuntimeTemplate },
  props: {
    id: {
      type: Number,
      default: null,
      required: false,
    },
    identifier: {
      type: String,
      default: null,
      required: false,
    },
    sync: {
      type: Boolean,
      default: false,
      required: false,
    },
    showtitle: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  serverPrefetch() {
    return this.fetchCmsBlock();
  },
  created() {
    if (!isServer) {
      this.fetchCmsBlock();
    }
  },
  methods: {
    fetchCmsBlock() {
      let queryKey = "";
      let queryValue = "";
      if (this.id) {
        queryKey = "id";
        queryValue = this.id;
      } else if (this.identifier) {
        queryKey = "identifier";
        queryValue = this.identifier;
      }
      if (queryKey && queryValue) {
        return this.$store.dispatch("cmsBlock/single", {
          key: queryKey,
          value: queryValue,
        });
      }
    },
    decodeHtmlEntities(str) {
      if (typeof window !== "undefined" && typeof document !== "undefined") {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = str;
        return textarea.value;
      }
      return str;
    }
  },
  computed: {
    getCmsData() {
      if (this.id) {
        return this.$store.getters[`cmsBlock/getCmsBlockById`](this.id);
      } else if (this.identifier) {
        return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          this.identifier
        );
      }
      return null;
    },
    decodedContent() {
      const content = this.getCmsData?.content || '';
      return this.decodeHtmlEntities(content);
    },
  },
};
</script>
<style lang="scss">
.cms-block-content {
  p {
    margin-bottom: 0px;
  }
  strong,
  b {
    font-weight: 500;
  }
}
</style>
